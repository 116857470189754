<template>
  <CRow>
    <CCol sm="12">
      <CJumbotron color="success">
          <h2>棒棒的，支付成功了。 联系客服进行接入。</h2>
          <hr />
          <CButton size="lg" color="info"  :to="{'name':'DomainList'}">前往域名管理</CButton>
      </CJumbotron>
    </CCol>
  </CRow>
</template>

<script>
import axios from '@/plugins/axios.js';

export default {
  name: 'MemberPaySuccess',

  data() {
    return {
      products: {},

      subscription: null
    }
  },
  methods: {
  },
}
</script>
